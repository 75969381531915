<template>
    <a-modal
        :visible="visible"
        :title="`${!form.id ? 'Tambah' : 'Edit'} Event`"
        @ok="ConversionPoint"
        @cancel="handleModalCancel"
        width="70%"
        :destroy-on-close="true"
        :footer="null">
    
        <AForm
            ref="formRef"
            class="myform"
            :model="form"
            :rules="state.rules"
            @keydown="form.onKeydown($event)"
            @finish="handleOk"
            :wrapper-col="{ span: 14 }"
            :scroll-to-first-error="true">

            <!-- event -->
            <a-row class="form-row">
                <a-col :sm="24">
                    <a-form-item
                        label="Judul Event"
                        label-align="left"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        required
                        name="event_name"
                        has-feedback>
                        <AInput
                            placeholder="nama event"
                            :disabled="form.readOnly"
                            v-model:value="form.event_name" />
                    </a-form-item>
                </a-col>
            </a-row>

            <!-- tipe aksi poin -->
            <a-row class="form-row">
                <a-col :sm="24">
                    <a-form-item
                        label="Tipe Aksi Poin"
                        label-align="left"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        required
                        name="operation_types"
                        has-feedback>
                        <FilterLoyaltyOperationalType
                            :mode="null"
                            style="width:100%;"
                            placeholder="Pilih Tipe Aksi Poin"
                            :disabled="form.readOnly"
                            v-model:value="form.operation_types"/>
                    </a-form-item>
                </a-col>
            </a-row>

            <!-- angka penambahan -->
            <a-row
                v-if="form.operation_types == 2"
                class="form-row">
                <a-col :sm="24">
                    <a-form-item
                        label="Angka Penambahan"
                        label-align="left"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        required
                        name="operation_value"
                        has-feedback>
                        <AInput
                            placeholder="number"
                            type="number"
                            :min="0"
                            v-model:value="form.operation_value"/>
                    </a-form-item>
                </a-col>
            </a-row>

            <!-- angka perkalian -->
            <a-row
                v-if="form.operation_types == 1"
                class="form-row">
                <a-col :sm="24">
                    <a-form-item
                        label="Angka Perkalian"
                        label-align="left"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        required
                        name="operation_value"
                        has-feedback>
                        <AInput
                            placeholder="number"
                            type="number"
                            :min="0"
                            v-model:value="form.operation_value"/>
                    </a-form-item>
                </a-col>
            </a-row>

            <!-- tipe event -->
            <a-row class="form-row">
                <a-col :sm="24">
                    <a-form-item
                        label="Tipe Event"
                        label-align="left"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        required
                        name="event_types"
                        has-feedback>
                        <FilterLoyaltyEventType
                            :mode="null"
                            style="width:100%;"
                            placeholder="Pilih Tipe Event"
                            @change="resetDateTipeEvent"
                            :disabled="form.readOnly"
                            v-model:value="form.event_types"/>
                    </a-form-item>
                </a-col>
            </a-row>

            <!-- tanggal event first (range) -->
            <a-row
                v-if="form.event_types == 2"
                class="form-row">
                <a-col :sm="24">
                    <a-form-item
                        label="Tanggal Mulai"
                        label-align="left"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        required
                        name="start_date"
                        has-feedback>
                        <ADatePicker
                            v-model:value="form.start_date"
                            :disabled-date="disabledStartDate"
                            placeholder="Tanggal Mulai"
                            style="width:100%;"
                            format="DD MMMM YYYY"/>
                    </a-form-item>
                </a-col>
            </a-row>

            <!-- tanggal event last (range) -->
            <a-row
                v-if="form.event_types == 2"
                class="form-row">
                <a-col :sm="24">
                    <a-form-item
                        label="Tanggal Akhir"
                        label-align="left"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        required
                        name="end_date"
                        has-feedback>
                        <ADatePicker
                            v-model:value="form.end_date"
                            :disabled-date="disabledEndDate"
                            placeholder="Tanggal Akhir"
                            style="width:100%;"
                            format="DD MMMM YYYY"/>
                    </a-form-item>
                </a-col>
            </a-row>

            <!-- ranges event baru -->
            <ARow class="form-row">
                <ACol :sm="24">
                    <ASpace
                        direction="vertical"
                        style="width:100%">
                        <ACard
                            v-for="(item, index) in form.ranges"
                            :key="index"
                            size="small">
                            <strong class="mb-2">Tanggal Event</strong>

                            <!-- date -->
                             <ARow class="form-row">
                                <ACol :sm="24">
                                    <AFormItem
                                        label="Pilih Tanggal"
                                        label-align="left"
                                        :label-col="{ sm: { span: 4 } }"
                                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                                        required
                                        :name="['ranges', index, 'start_date']"
                                        has-feedback>
                                        <ADatePicker
                                            v-model:value="item.start_date"
                                            placeholder="Tanggal"
                                            style="width:100%;"
                                            format="DD MMMM YYYY"/>
                                    </AFormItem>
                                </ACol>
                            </ARow>

                            <!-- delete tier -->
                            <ARow class="form-row" type="flex" justify="end">
                                <ACol>
                                    <AFormItem>
                                        <AButton
                                            type="danger"
                                            size="small"
                                            @click="removeRowReference(item)">
                                            <i class="fa fa-trash mr-2" /> Hapus
                                        </AButton>
                                    </AFormItem>
                                </ACol>
                            </ARow>
                        </ACard>
                    </ASpace>
                </ACol>
            </ARow>
            <!-- end ranges event baru -->
            
            <!-- add ranges event -->
            <ARow
                v-if="form.event_types == 3" 
                class="form-row"
                type="flex"
                justify="start">
                <ACol>
                    <AFormItem>
                        <AButton
                            type="primary"
                            size="small"
                            @click="AddReference">
                            <i class="fa fa-plus mr-2" /> Tambahkan Tanggal
                        </AButton>
                    </AFormItem>
                </ACol>
            </ARow>

            <a-row class="form-row" type="flex" justify="end">
                <a-col>
                    <a-form-item>
                        <a-button
                            type="primary"
                            html-type="submit"
                            :loading="form.busy"
                            :disabled="form.busy">{{
                            !form.id ? 'Simpan' : 'Perbarui'
                        }}</a-button>
                    </a-form-item>
                </a-col>
            </a-row>
        </AForm>

        <div v-if="form.errors.any()">
            <ASpace>
                <AAlert
                    v-for="(item, index) in form.errors.all()"
                    type="error"
                    :key="index"
                    :message="item"
                    banner
                    closable
                    @close="form.clear()" />
            </ASpace>
        </div>
    </a-modal>
</template>
<script>
import {
    defineComponent,
    onMounted,
    reactive,
    ref,
} from 'vue'
import Form from 'vform'
import apiClient from '@/services/axios'
import { Modal, message } from 'ant-design-vue'
import FilterLoyaltyEventType from '@/components/filter/FilterLoyaltyEventType'
import FilterLoyaltyOperationalType from '@/components/filter/FilterLoyaltyOperationalType'
import moment from 'moment'

export default defineComponent({
    components: {
        FilterLoyaltyEventType,
        FilterLoyaltyOperationalType,
    },
    props: {
        visible: [Boolean],
        program_id: {
            type: [String, Number],
            default: null,
        },
        item: {
            type: Object,
            default: () => ({
                id: null,
            }),
        },
    },
    emits: ['handleOkConversionPoint', 'update:visible'],
    setup(props, { emit }) {
        const form = reactive(new Form({
            readOnly: false,
            id: props.item.id,
            id_program: props.program_id,
            event_name: null,
            operation_types: null,
            operation_value: null,
            event_types: null,
            start_date: null,
            end_date: null,
            ranges: [],
        }))

        const formRef = ref();

        const state = reactive({
            data: [],
            reference: {
                id: null,
                start_date: null,
            },
            page: 1,
            per_page: 1,
            total: 0,
            loading: false,
            TypePointLists: [
                {
                    label: "Pengkalian Poin",
                    value: 1,
                },
                {
                    label: "Penambahan Poin",
                    value: 2,
                },
            ],
            TypeEventLists: [
                {
                    label: "Berlaku Periode",
                    value: 1,
                },
                {
                    label: "Berlaku Tanggal",
                    value: 2,
                },
                {
                    label: "Ulang Tahun",
                    value: 3,
                },
            ],
            rules: {
                event_name: [
                    {
                        required: true,
                        message: "Judul event tidak boleh kosong!",
                    },
                ],
                operation_types: [
                    {
                        required: true,
                        message: "Tipe aksi poin tidak boleh kosong!",
                    },
                ],
                operation_value: [
                    {
                        required: true,
                        message: "Angka operational tidak boleh kosong!",
                    },
                ],
                event_types: [
                    {
                        required: true,
                        message: "Tipe event tidak boleh kosong!",
                    },
                ],
                start_date: [
                    {
                        required: true,
                        message: "Tanggal mulai tidak boleh kosong!",
                    },
                ],
                end_date: [
                    {
                        required: true,
                        message: "Tanggal akhir tidak boleh kosong!",
                    },
                ],
                ranges: [],
            },
            rule_references: {
                start_date: [
                    {
                        required: true,
                        message: "Tanggal tidak boleh kosong!",
                    },
                ],
            },
        })

        const handleModalCancel = () => {
            emit('update:visible', false)
            emit('update:item', null)
        }

        const disabledStartDate = (current) => {
            return current && current > moment(form.end_date)
        }

        const disabledEndDate = (current) => {
            return current && moment(form.start_date) >= current
        }

        const removeRowReference = (item) => {
            const index = form.ranges.indexOf(item)

            if (item !== -1) {
                form.ranges.splice(index, 1)
                state.rules.ranges.splice(index, 1)
            }
        }

        const AddReference = () => {
            if (form.ranges.filter(v => v.start_date === null).length > 0) {
                message.warning('Pastikan ranges sebelumnya terisi!')
                return
            }
            
            form.ranges.push(Object.assign({}, state.reference))
            state.rules.ranges.push(Object.assign({}, state.rule_references))
        }

        const resetDateTipeEvent = () => {
            form.start_date = null
            form.end_date = null
            form.ranges = []
            state.rules.ranges = []
        }

        const handleOk = async () => {
            // validation form
            await formRef.value.validate()
                .catch(() => {});

            form.date = []
            form.ranges.forEach(item => {
                form.date.push(item.start_date)
            })

            if (!form.id) {
                form.post('/api/loyalty-program-events')
                    .then(({ data }) => {
                        if (data === undefined) {
                            errorMessage.value = `Kode error 500, No response from server`
                            return
                        }

                        message.success('Berhasil menyimpan')

                        handleModalCancel()
                        emit('success', data)
                    })
            } else {
                delete form.password
                delete form.retype_password

                form.put(`/api/loyalty-program-events/${form.id}`)
                    .then(({ data }) => {
                        if (data === undefined) {
                            errorMessage.value = `Kode error 500, No response from server`
                            return
                        }

                        message.success('Berhasil memperbarui')

                        handleModalCancel()
                        emit('success', data)
                    })
            }

        }

        // handle func vue
        onMounted(() => {
            form.reset()

            if (form.id) {
                form.event_name = props.item.event_name
                form.operation_types = props.item.operation_types
                form.operation_value = props.item.operation_value
                form.event_types = props.item.event_types
                form.start_date = props.item.start_date
                form.end_date = props.item.end_date

                props.item.event_dates.forEach(item => {
                    form.ranges.push({
                        id: item.id,
                        start_date: item.event_date,
                    })
                })
            }
        })

        return {
            moment,
            formRef,
            handleOk,
            form,
            state,
            handleModalCancel,
            disabledStartDate,
            disabledEndDate,
            removeRowReference,
            AddReference,
            resetDateTipeEvent,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/css/form.scss';
</style>
